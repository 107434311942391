.chatMessenger {
  position: relative;
  // margin-top: 2rem;
  // height: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: center;

  padding: 1rem 0.3rem;

  border-radius: 8px;
  background: #f1eeee;
  width: 100%;

  .mesagesContainer {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow: scroll;
    margin-bottom: 4rem;
  }

  #sendMensageInput {
    position: absolute;
    bottom: 5px;
    left: 4px;
    width: 98%;


    form {
      padding: 0;
    }

    input {
      width: 82%;
      bottom: 0px;
      border-radius: 0.2rem;
      cursor: text;
      height: 2.5rem;
      border: 1px solid #fff;
      transition: all 0.4s;

      &:focus {
        border: 1px solid var(--primary);
      }
    }

    .inputMessageIconsContainer {
      position: absolute;
      bottom: 16px;
      right: 15px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }


  .blockDateImage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 6px;

    img {
      border-radius: 4px;
      width: 225px;
      height: 125px;
    }

    legend {
      margin-top: 6px;
      margin-bottom: 12px;
      margin-right: 12px;
      // padd
      font-size: 1.05rem;
      font-weight: 400;
      color: #fff;
      border-radius: 4px;
      padding: 6px;
      background: var(--secondary);
    }
  }

  strong {
    font-weight: 600;
    margin-left: 12px;
    padding: 12px;
    text-align: flex-start;
    width: 120px;
    border-radius: 4px;
    color: var(--white);
  }

  .containerImagesOccurrences {
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    align-items: flex-end;

    img {
      width: 225px;
      height: 125px;
      border-radius: 4px;
      cursor: pointer;
      border: 4px solid var(--secondary);
    }

    span {
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      background: var(--secondary);
      padding: 12px;
      width: "auto";
      border-radius: 4px;
      text-justify: justify;
      // width: 20px;
      margin-bottom: 6px;
    }

    span:last-child {
      max-width: 60%;
      font-size: 0.85rem;
      font-weight: 400;
      margin-bottom: 24px;
      margin-top: 6px;
    }
  }
}

.containerReceivedMensages {
  align-self: start;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  min-width: 100px;
  margin-bottom: 12px;
  // align-items: center;
  justify-content: space-between;
  color: rgb(15, 15, 15);
  font-size: 1rem;
  background: #ffffff;
  padding: 12px;
  padding-bottom: 1.5rem;
  width: "auto";
  border-radius: 8px;
  // width: 20px;
  // margin-right: 0.5rem;

  p {
    position: absolute;
    margin: 0;
    font-size: 0.8rem;
    color: rgb(90, 90, 90);
    bottom: 5px;
    right: 10px;
  }

  img {
    width: 225px;
    height: 125px;
    border-radius: 4px;
    cursor: pointer;
    border: 4px solid var(--secondary);
  }

}

.containerSendMensages {
  align-self: end;
  position: relative;
  display: flex;
  flex-direction: column;

  max-width: 80%;
  min-width: 55px;
  margin-bottom: 12px;
  // align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 1rem;
  background: var(--secondary);
  padding: 12px;
  padding-bottom: 1.5rem;
  width: "auto";
  border-radius: 8px;
  // width: 20px;
  // margin-right: 0.5rem;

  p {
    position: absolute;
    margin: 0;
    font-size: 0.8rem;
    color: rgb(219, 219, 219);
    bottom: 5px;
    right: 10px;
  }

  img {
    width: 225px;
    height: 125px;
    border-radius: 4px;
    cursor: pointer;
    border: 4px solid var(--secondary);
  }

}