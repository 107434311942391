.popupCreateDocument {
    // margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--secondary);
    border-radius: 8px;
    // width: 320px;

    .headerDocument {
        width: 100%;
        margin-bottom: 8px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    form {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        padding: 12px;
        background: var(--white);
        width: 100%;

        .collectOrDelivery {
            display: flex;
            justify-content: center;

            .label-active {
                background: var(--primary);
                color: var(--black);
            }

            label {
                width: 255px;
                height: 55px;
                border-radius: 32px;
                border: 1px solid var(--lineGray);
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                background: var(--white);
                color: var(--lineGray);
                display: flex;
                font-weight: 600;
                align-items: center;
                justify-content: flex-start;
                padding: 22px;
                margin-bottom: 35px;
                position: relative;

                input {
                    width: 1.1em;
                    height: 1.1em;
                    background-color: white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid var(--lineGray);
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    margin-right: 12px;
                }

                img {
                    position: absolute;

                    cursor: pointer;
                    // left: 140px;
                }
            }

            label+label {
                margin: 0 12px;
            }
        }

        legend {
            margin-bottom: 12px;
            font-size: 0.95rem;
            font-weight: bold;
            color: var(--secondary);
        }

        #infoDestiny {
            margin-top: 25px;
        }

        .infoDocumentos {
            display: grid;
            grid-template-columns: 70% 30%;

            .blockInfoDocuments+.blockInfoDocuments {
                margin-left: 12px;
            }

            .blockInfoDocuments {
                display: flex;
                flex-direction: column;
                gap: 8px;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                input {
                    width: 100%;
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }

                input:focus {
                    background: #ecebeb;
                }
            }
        }

        .input-form-createDocuments {
            z-index: 1000;
            display: grid;

            .blockInput-createDocuments {
                gap: 8px;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                position: relative;

                .inputTime {
                    input {
                        width: 100%;
                        position: relative;
                    }

                    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
                        // background: transparent;
                        position: absolute;
                        padding-left: 100%;
                        right: 0;
                    }
                }

                .gridAddress {
                    display: flex;

                    input:first-child {
                        width: 60%;
                    }

                    input {
                        width: 15%;
                        margin-right: 16px;
                    }

                    //     grid-template-columns: 52% 10% 10% 10%;
                    //    gap: 12px;
                    input:focus {
                        background: #ecebeb;
                    }
                }

                input {
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }

                input:focus {
                    background: #ecebeb;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }

                select {
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    // -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }
            }

            .blockInput-createDocuments-secondary {
                display: grid;
                grid-template-columns: 50% 50%;

                .blockInput-createDocuments+.blockInput-createDocuments {
                    margin-left: 12px;
                }
            }
        }

        .input-form-createDocuments+.input-form-createDocuments {
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .button-form {
            margin: 20px 0 15px 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button {
                width: 165px;
                height: 45px;
                background: var(--secondary);
                color: var(--white);
                border: none;
                border-radius: 50px;
                color: var(--secondary);

                margin-right: 12px;
                padding: 12px;
                // -webkit-appearance: none;
                // outline: none;
                transition: filter 0.5;
                display: flex;
                align-items: center;
                background: var(--white);
                justify-content: center;
            }

            button:hover {
                filter: brightness(0.9);
            }

            button+button {
                padding-right: 12px;
            }

            button:last-child:hover {
                filter: brightness(0.98);
            }

            button:last-child {
                color: var(--white);

                background: var(--secondary);
                border: 1px solid var(--lineGray);
            }
        }
    }

}

form {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    padding: 12px;
    background: var(--white);
    width: 100%;

    .collectOrDelivery {
        display: flex;
        justify-content: center;

        .label-active {
            background: var(--primary);
            color: var(--black);
        }

        label {
            width: 255px;
            height: 55px;
            border-radius: 32px;
            border: 1px solid var(--lineGray);
            box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            background: var(--white);
            color: var(--lineGray);
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: flex-start;
            padding: 22px;
            margin-bottom: 35px;
            position: relative;

            input {
                width: 1.1em;
                height: 1.1em;
                background-color: white;
                border-radius: 50%;
                vertical-align: middle;
                border: 1px solid var(--lineGray);
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
                margin-right: 12px;
            }

            img {
                position: absolute;

                cursor: pointer;
                // left: 140px;
            }
        }

        label+label {
            margin: 0 12px;
        }
    }

    legend {
        margin-bottom: 12px;
        font-size: 0.95rem;
        font-weight: bold;
        color: var(--secondary);
    }

    #infoDestiny {
        margin-top: 25px;
    }

    .infoDocumentos {
        display: grid;
        grid-template-columns: 85% 15%;

        .blockInfoDocuments+.blockInfoDocuments {
            margin-left: 12px;
        }

        .blockInfoDocuments {
            display: flex;
            flex-direction: column;
            gap: 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            input {
                width: 100%;
                padding: 6px 12px;
                border: 1px solid var(--lineGray);
                border-radius: 4px;
                font-size: 0.85rem;
                -webkit-appearance: none;
                outline: none;
                box-shadow: 1px 1px #888888;
            }

            input::placeholder {
                font-style: italic;
            }

            input::-webkit-input-placeholder {
                padding-left: 0;
            }

            input:focus {
                background: #ecebeb;
            }
        }
    }

    .input-form-createDocuments {
        z-index: 1000;
        display: grid;

        .blockInput-createDocuments {
            gap: 8px;
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            position: relative;

            .inputTime {
                input {
                    width: 100%;
                    position: relative;
                }

                input[type="datetime-local"]::-webkit-calendar-picker-indicator {
                    // background: transparent;
                    position: absolute;
                    padding-left: 100%;
                    right: 0;
                }
            }

            .gridAddress {
                display: flex;

                input:first-child {
                    width: 60%;
                }

                input {
                    width: 15%;
                    margin-right: 16px;
                }

                //     grid-template-columns: 52% 10% 10% 10%;
                //    gap: 12px;
                input:focus {
                    background: #ecebeb;
                }
            }

            input {
                padding: 6px 12px;
                border: 1px solid var(--lineGray);
                border-radius: 4px;
                font-size: 0.85rem;
                -webkit-appearance: none;
                outline: none;
                box-shadow: 1px 1px #888888;
            }

            input:focus {
                background: #ecebeb;
            }

            input::placeholder {
                font-style: italic;
            }

            input::-webkit-input-placeholder {
                padding-left: 0;
            }

            select {
                padding: 6px 12px;
                border: 1px solid var(--lineGray);
                border-radius: 4px;
                font-size: 0.85rem;
                // -webkit-appearance: none;
                outline: none;
                box-shadow: 1px 1px #888888;
            }
        }

        .blockInput-createDocuments-secondary {
            display: grid;
            grid-template-columns: 50% 50%;

            .blockInput-createDocuments+.blockInput-createDocuments {
                margin-left: 12px;
            }
        }
    }

    .input-form-createDocuments+.input-form-createDocuments {
        gap: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .button-form {
        margin: 20px 0 15px 0;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        button {
            width: 165px;
            height: 45px;
            background: var(--secondary);
            color: var(--white);
            border: none;
            border-radius: 50px;
            color: var(--secondary);

            margin-right: 12px;
            padding: 12px;
            // -webkit-appearance: none;
            // outline: none;
            transition: filter 0.5;
            display: flex;
            align-items: center;
            background: var(--white);
            justify-content: center;
        }

        button:hover {
            filter: brightness(0.9);
        }

        button+button {
            padding-right: 12px;
        }

        button:last-child:hover {
            filter: brightness(0.98);
        }

        button:last-child {
            color: var(--white);

            background: var(--secondary);
            border: 1px solid var(--lineGray);
        }
    }
}

#buttonOpenPopup {
    opacity: 1;
    outline: none;
    border: none;
    background: transparent;
}

@media (max-width: 520px) {
    .popupCreateDocument {
        form {
            .collectOrDelivery {
                label {
                    width: 120px;
                    height: 25px;
                    font-size: 0.85rem;
                }
            }
        }
    }
}