.popupCreateDocument {
  // margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--secondary);
  border-radius: 8px;

  // width: 320px;

  .headerDocument {
    width: 100%;
    margin-bottom: 8px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    span {
      font-weight: bold;
      // color: green;
    }
  }

  form {
    overflow: scroll; //Pay attention to this !!!!!!!
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    padding: 12px;
    background: var(--white);
    width: 100%;

    .selectSelected {
      color: var(--black);
    }

    .selectDefault {
      color: var(--textGray);
      font-style: italic;
    }

    .infoDocumentos {
      display: grid;
      grid-template-columns: 50% 50%;

      .blockInfoDocuments {
        display: flex;
        flex-direction: column;
        gap: 8px;
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        &:nth-child(even) {
          margin-left: 12px;
        }

        label {
          font-size: 0.8rem;
          color: var(--textGray);
        }

        input {
          width: 100%;
          padding: 6px 12px;
          border: 1px solid var(--lineGray);
          border-radius: 4px;
          font-size: 0.85rem;
          -webkit-appearance: none;
          outline: none;
          box-shadow: 1px 1px #888888;
          cursor: text;
        }

        select {
          padding: 6px 12px;
          border: 1px solid var(--textGray);
          border-radius: 4px;
          font-size: 0.85rem;
          // font-style: italic;
          font-weight: 100;
          // -webkit-appearance: none;
          outline: none;
          box-shadow: 1px 1px #888888;
          cursor: pointer;

          option {
            font-style: normal;

            &:first-child {
              font-style: italic;
            }
          }
        }

        input::placeholder {
          font-style: italic;
        }

        input::-webkit-input-placeholder {
          padding-left: 0;
        }

        input:focus {
          background: #ecebeb;
        }
      }

      .blockMinMax {
        margin: 5px 0px 0px 10px;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 8px;
        margin-bottom: 12px;

        .valueMinMax {
          input {
            display: block;
            width: 100%;
            height: 31px;
            padding: 6px 12px;
            border: 1px solid var(--lineGray);
            border-radius: 4px;
            font-size: 0.85rem;
            -webkit-appearance: none;
            outline: none;
            box-shadow: 1px 1px #888888;
            cursor: text;
          }

          label {
            font-size: 0.8rem;
            color: var(--textGray);
          }

        }
      }
    }

    .blockForms {
      // border: solid 1px;
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 0.5fr 0.5fr 1fr;
      font-size: 0.8rem;
      padding: 0 10px;

      span {
        font-size: 0.7rem;
        font-weight: 500;
        color: var(--secondary);

        &:last-child {
          text-align: center;
        }
      }
    }

    .blockFormsContainer {
      width: 100%;
      height: 200px;
      border-radius: 8px;
      border: solid 2px var(--secondary);

      .formsInfo {
        height: 30px;
        margin: 5px 5px;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 0.5fr 0.5fr 1fr;
        border: solid 2px var(--lineGray);
        border-radius: 5px;
        align-items: center;
        transition: all 0.1s;
        cursor: pointer;

        &:hover {
          background: var(--secondary);

          p {
            color: #fff;
          }
        }

        p {
          margin: 0;
          font-size: 0.7rem;

          &:last-child {
            text-align: center;
          }

          &:first-child {
            margin-left: 15px;
          }
        }
      }
    }

    .buttonFormsContainer {
      padding: 20px;
      border-bottom: solid 2px var(--lineGray);
      display: flex;

      button {
        width: 70px;
        height: 35px;
        font-size: 0.8rem;
        background: var(--secondary);
        color: var(--white);
        border-radius: 50px;
        padding: 12px;
        -webkit-appearance: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;

        button:last-child {
          background: var(--secondary);
          color: var(--white);
        }

        &+button {
          margin-left: 10px;
        }

        &:hover {
          border: none;
          background: var(--primary);
        }
      }
    }

    .submitFormsContainer {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;

      button {
        width: 135px;
        height: 35px;
        background: var(--white);
        border: 1px solid var(--secondary);
        color: var(--black);
        border-radius: 50px;
        margin-right: 12px;
        padding: 12px;
        -webkit-appearance: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--lineGray);
        }

        &:last-child {
          background: var(--secondary);
          color: var(--white);
          border: 1px solid var(--lineGray);
          margin-left: 10px;

          &:hover {
            border: none;
            background: var(--primary);
          }
        }
      }
    }
  }
}