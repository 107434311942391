#imgLogo {
  width: 320px;
  height: 75px;
  padding: 0;
}

@media (max-width: 768px) {
  #imgLogo {
    width: 200px;
    height: 95px;
  }
}

.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 356px;
  height: 296px;
  margin-top: 30px;
  color: black;
  background: white;
  border-radius: 10px;
}

.card {
  max-width: 290px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding-top: 30px;
}

.input-label {
  color: #696969;
  cursor: pointer;
}

.necessary-icon {
  color: rgb(236, 58, 58);
}

input {
  position: relative;
  font-family: "Segoe UI", Semilight italic;
  color: #444444;
  padding-left: 10px;
  outline: none;
}

input::placeholder {
  padding: 0;
}

.password-view {
  position: relative;
  top: 56%;
  left: 70.5%;
  z-index: 10;
  cursor: pointer;
}

.input-text {
  position: relative;
  width: 290px;
  height: 40px;
  box-shadow: 0px 1px 0px #bebebe;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-top: 5px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.85rem;
  padding-left: 10px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.85rem;
  padding-left: 10px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.85rem;
  padding-left: 10px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.85rem;
  padding-left: 10px;
}

.input-wrapper {
  margin-top: 12px;
}

// .input-wrapper:first-child {
//   margin-top: 12px;
// }

.login-alert {
  color: red;
  width: 135%;
  text-align: center;
  position: relative;
  top: 1rem;
  font-size: 0.85rem;
}

.button-icon {
  color: var(--primary);
  width: 22px;
  height: 15px;
}

.access-button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 350px;
  background: var(--secondary);
  box-shadow: 0px 1px 0px #bebebe;
  border-radius: 80px;
  border: none;
  color: #f7f8fa;
  margin: 30px;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  transition: filter 0.2;
  padding: 18px 0;
}

.access-button:hover {
  filter: brightness(0.9);
}

// @media (max-width: 568px) {
//   .card-wrapper {
//     width: 90%;
//     height: 296px;
//   }
// }

@media (max-width: 320px) {
  .card-wrapper {
    width: 80%;
  }

  .card {
    width: 70%;
  }

  .input-text {
    width: 100%;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .access-button {
    width: 100%;
    margin: 30px 0;
  }

  .password-view {
    left: 62%;
  }
}