.formsInfo {
    height: 30px;
    margin: 5px 5px;
    display: grid;
    grid-template-columns: 0.5fr 1.4fr 2.2fr 1fr 0.3fr 0.8fr;
    border: solid 2px var(--lineGray);
    border-radius: 5px;
    align-items: center;
    transition: all 0.1s;
    cursor: pointer;

    &:hover {
        background: var(--secondary);

        p {
            color: #fff;
        }
    }

    p {
        margin: 0;
        font-size: 0.7rem;

        &:last-child {
            text-align: center;
        }

        &:first-child {
            margin-left: 15px;
        }
    }
}

.formsInfoSelected {
    height: 30px;
    margin: 5px 5px;
    display: grid;
    grid-template-columns: 0.5fr 1.4fr 2.2fr 1fr 0.3fr 0.8fr;
    border: solid 2px var(--lineGray);
    border-radius: 5px;
    align-items: center;
    transition: all 0.1s;
    cursor: pointer;
    background: var(--secondary);

    p {
        color: #fff;
        margin: 0;
        font-size: 0.7rem;

        &:last-child {
            text-align: center;
        }

        &:first-child {
            margin-left: 15px;
        }
    }
}