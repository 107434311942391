.main {
    background: var(--white);
    // opacity: 0.2;
    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 20px;
    padding-right: 30px;

    section {
        display: grid;
        grid-template-columns: 0.1fr 0.38fr 1fr 1fr 0.5fr 0.3fr;
        width: 100%;
        //   padding-right: 10px;

        span {
            font-size: 0.75rem;
            font-weight: 600;
        }

        .blockInfo:first-child {
            display: flex;
            justify-content: flex-start;

            img {
                margin: 0 15px;
                width: 12px;
                height: 12px;
                cursor: pointer;
                z-index: 100;
            }
        }

        .blockInfo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex: 1;

            .blockFistData {
                width: 100%;
                display: grid;
                grid-template-columns: 38% 30% 40%;
            }

            button {
                position: absolute;
                right: 100px;
                background: var(--secondary);
                color: var(--white);
                // width: 20px;
                padding: 4px 12px;
                margin-right: 12px;
                border: none;
                border-radius: 12px;
                font-size: 0.9rem;
                transition: 0.2s filter;
            }

            button:hover {
                filter: brightness(0.9);
            }
        }

        .blockInfo:last-child {
            width: 100%;
            justify-content: flex-end;
        }
    }

    .changeCardBlock {
        position: relative;

        .changeCard {
            // position: absolute;
            // background: var(--white);
            // border-radius: 4px;
            // display: flex;
            // flex-direction: column;
            // padding: 12px 14px;
            // right: 8px;
            // width:100px;
            // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
            // color: var(--black);
            // top: 2px;

            span:first-child {
                margin-top: 8px;
            }

            span {
                margin-bottom: 6px;
                cursor: pointer;
                padding: 0 4px;
                border-radius: 8px;

                &:hover {
                    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                    // color: white;
                }

                &:active {
                    color: #e9e9e9;
                    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                }

                &__disabled {
                    color: #999999;
                    pointer-events: none;
                }
            }

            span:focus {
                background: linear-gradient(to top, #555, #333);
            }
        }
    }
}

.mainFocus {
    background: rgba(2, 0, 57, 0.05);
    border-left: 12px solid var(--primary);
}

.warning {
    border-left: 12px solid var(--primary);
    border: 1px solid var(--warning);
}

.green {
    border-left: 12px solid var(--primary);
    border: 1px solid var(--primary);
}

[role="tooltip"].infoUserCpfCnpjContent {
    width: 85px;
}

[role="tooltip"].mypopupRomaneioContent {
    width: 85px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;

    .changeCard {
        // position: absolute;
        // background: var(--white);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        // padding: 12px 6px;
        // right: 0;
        // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        // color: var(--black);
        // gap: 6px;

        span {
            font-size: 0.95rem;
            cursor: pointer;
            transition: filter 0.2;
        }

        span:hover {
            filter: brightness(0.1);
        }
    }
}