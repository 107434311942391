.ant-timeline-item-tail {
    border-left: 2px solid var(--secondary);
}

.buttonOccurrences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    button {
        min-width: 200px;
        padding: 12px 0px;
        // height: 16px;
        font-size: 0.95rem;
        font-weight: 600;
        border-radius: 36px;
        background: var(--warning);
        margin-right: 12px;
        margin-left: 12px;
        border: none;
        transition: 0.2s filter;
        color: white;
    }

    button:hover {
        filter: brightness(0.9);
    }

    button:last-child {
        color: white;
        margin-right: 0;
        background: var(--primary);
    }
}

.imagesOccurrences {
    // margin-top: 2rem;
    display: flex;
    flex-direction: column;
    max-height: 510px;
    overflow: scroll;

    .allowDelivery {
        background: var(--primary);
    }

    .returnDelivery {
        background: var(--warning);
    }

    strong {
        font-weight: 600;
        margin-left: 12px;
        padding: 12px;
        text-align: flex-start;
        width: 120px;
        border-radius: 4px;
        color: var(--white);
    }

    .containerImagesOccurrences {
        display: flex;
        margin-bottom: 12px;
        flex-direction: column;
        align-items: flex-end;

        img {
            width: 225px;
            height: 125px;
            border-radius: 4px;
            cursor: pointer;
            border: 4px solid var(--secondary);
        }

        span {
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            background: var(--secondary);
            padding: 12px;
            width: "auto";
            border-radius: 4px;
            text-justify: justify;
            // width: 20px;
            margin-bottom: 6px;
        }

        span:last-child {
            max-width: 60%;
            font-size: 0.85rem;
            font-weight: 400;
            margin-bottom: 24px;
            margin-top: 6px;
        }
    }
}

.info {
    padding: 0 0 12px 0;

    header {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 12px;
        height: 60px;
        background: var(--secondary);
        display: flex;
        align-items: center;

        span {
            color: var(--white);
            font-size: 1.05rem;
            font-weight: 600;
        }
    }

    main {
        padding: 0 18px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .infoHeader {
            display: flex;
            justify-content: space-between;
            height: 4rem;
            padding: 1rem;

            .delivery {
                background: var(--delivery);
            }

            .collect {
                background: var(--collect);
            }

            .finish {
                background: var(--finish);
            }

            .warning {
                background: var(--warning);
            }

            span {
                padding: 4px 16px;
                border-radius: 8px;
                font-size: 1rem;
                color: var(--white);
                font-weight: normal;
            }

            p {
                text-decoration: underline;
                color: var(--secondary);
            }

            .imageStatus {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    font-weight: bold;
                    font-size: 0.8rem;
                    text-decoration: none;
                    color: var(--secondary);
                    padding-bottom: 3px;
                    margin: 0 5px;
                }
            }

            .anexo {
                display: flex;
                align-items: center;
                cursor: pointer;

                a {
                    color: var(--secondary);
                }

                img {
                    margin-right: 6px;
                }
            }
        }

        .infoAndChat {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;

            .chatEmpty {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                margin-bottom: 2rem;
            }

            .chat {
                border-radius: 8px;
                background: #f1eeee;
                min-width: 300px;

                // height: 300px;

                .blockDateImage {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    padding: 6px;

                    img {
                        border-radius: 4px;
                        width: 225px;
                        height: 125px;
                    }

                    legend {
                        margin-top: 6px;
                        margin-bottom: 12px;
                        margin-right: 12px;
                        // padd
                        font-size: 1.05rem;
                        font-weight: 400;
                        color: #fff;
                        border-radius: 4px;
                        padding: 6px;
                        background: var(--secondary);
                    }
                }
            }

            .chatImages {
                display: flex;
                flex-direction: column;


            }

            aside {
                .block-header {
                    margin-bottom: 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    span {
                        color: var(--secondary);
                        font-size: 0.85rem;
                        font-weight: 700;
                        // margin-right: 60px;
                    }

                    p {
                        color: var(--secondary);
                        font-size: 0.95rem;
                    }


                }

                .block-header:first-child {
                    .delivery {
                        background: var(--delivery);
                    }

                    .collect {
                        background: var(--collect);
                    }

                    .finish {
                        background: var(--finish);
                    }

                    .warning {
                        background: var(--warning);
                    }

                    span {
                        padding: 4px 16px;
                        border-radius: 8px;
                        font-size: 1rem;
                        color: var(--white);
                        font-weight: normal;
                    }

                    p {
                        text-decoration: underline;
                        color: var(--secondary);
                    }
                }

                .infoAndChatMain {
                    display: flex;
                    justify-content: space-between;



                    .info {
                        .blockInfoCard {
                            margin-bottom: 4px;

                            span {
                                color: var(--black);
                                font-size: 0.9rem;
                                font-weight: 400;
                                margin-bottom: 100px;
                                // width: 20px;
                            }

                            p {
                                color: var(--textGray);
                                font-size: 0.85rem;
                                font-weight: 400;
                                width: 255px;
                            }
                        }

                        .blockInfoCard:last-child {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 25px;

                            span {
                                margin: 6px 0;
                            }

                            label {
                                font-size: 0.95rem;
                                color: var(--textGray);
                            }
                        }
                    }


                }
            }

            aside:last-child {
                flex: 1;
            }
        }

        .buttonBlock {
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    margin-top: 4px;
                    margin-right: 8px;
                }

                span {
                    padding: 0;
                    margin: 0;
                    text-decoration: underline;
                    color: var(--secondary);
                }
            }

            #finished {
                padding: 12px 36px;
                background: var(--secondary);
                border: none;
                font-size: 0.95rem;
                border-radius: 30px;
                color: var(--white);
                transition: filter 0.2;
                outline: none;
            }

            button:hover {
                filter: brightness(0.9);
            }
        }
    }
}



.timeLineItem {
    h3 {
        font-size: 0.9rem;
        color: var(--secondary);
        margin-bottom: 0;
    }

    p {
        font-size: 0.8rem;
        margin: 0;
        color: var(--secondary);
    }

    span {
        font-size: 0.8rem;
        margin-top: 10px;
        color: var(--secondary);
    }
}

.imageContainer {
    background: #f1eeee;
    display: block;
    // h3 {
    //     position: absolute;
    //     margin-left: 10px;
    //     position: absolute;
    //     bottom: 5px;
    //     right: 20px;
    //     color: var(--secondary);
    // }

    .imageDownloadContainer {
        bottom: 5px;
        right: 20px;
        display: flex;
        position: absolute;
        align-items: center;
        cursor: pointer;

        h3 {
            margin-left: 10px;
            margin-right: 10px;
            bottom: 5px;
            right: 20px;
            color: var(--secondary);
        }
    }
}



.imageDownloadEventContainer {
    bottom: 10px;
    right: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    cursor: pointer;

    h3 {
        margin-left: 10px;
        margin-right: 10px;
        color: var(--secondary);
    }
}

@media (max-width: 1500px) {
    .info {
        padding: 0;

        .chat {
            width: auto;
        }

        main {
            .infoAndChat {
                aside {
                    overflow: scroll;
                    max-height: 500px;

                    .block-header {
                        p {
                            padding-right: 20px;
                        }
                    }

                    .infoAndChatMain {
                        display: flex;
                        flex-direction: column;




                    }
                }
            }

            .buttonBlock {
                padding-bottom: 30px;
            }
        }
    }
}

// @media (max-width: 480px) {
//     .info {
//         padding: 0;
//         main {
//             height: 540px;
//             overflow: scroll;

//             .infoAndChat {
//                 aside {
//                     .block-header {
//                         p {
//                             padding-right: 20px;
//                         }
//                     }
//                     .infoAndChatMain {
//                         .chatImages {
//                             padding-right: 20px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 720px) {
//     .info {
//         padding: 0;
//         main {
//             height: 680px;
//             overflow: scroll;

//             .infoAndChat {
//                 aside {
//                     .block-header {
//                         p {
//                             padding-right: 20px;
//                         }
//                     }
//                     .infoAndChatMain {
//                         .chatImages {
//                             padding-right: 20px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }