.content-painel-users {
  height: 100vh;
  background: var(--background);

  header {
    background: var(--secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    legend {
      font-size: 1.75rem;
      font-weight: bold;
      color: var(--white);
    }

    img {
      width: 145px;
      height: 45px;
    }

    label {
      width: 35px;
      height: 35px;
      background: var(--primary);
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: bold;
      font-size: 1rem;
      color: var(--secondary);
    }
  }

  main {
    background: var(--background);

    .section-buttons {
      margin-top: 35px;

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 12px 0;
      margin-bottom: 12px;

      button {
        width: 235px;
        height: 45px;
        margin-right: 12px;
        background: var(--secondary);
        color: var(--white);
        padding: 6px 24px;
        border-radius: 36px;
        font-size: 0.85rem;
      }

      input {
        width: 50%;
        border-radius: 4px;
        font-size: 0.95rem;
        border: none;
        padding: 12px 24px;
        margin-left: 12px;
      }
    }

    .sections-companies {
      margin-top: 35px;

      .titles-companies {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        legend {
          border-left: 1px solid var(--black);
          border-right: 1px solid var(--black);
          font-size: 1.15rem;
          font-weight: 500;
          text-align: center;
          padding: 12px;
        }

        background: var(--lineGray);
        border: 1px solid var(--black);
      }

      .block-company {
        .block-company-item {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          border: 1px solid var(--textGray);
          align-items: center;
          border-left: 1px solid var(--black);
          // padding-top: 5px;
          // padding-bottom: 5px;
          height: 50px;
          // padding: 8px;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;

          strong {
            border-right: 1px solid var(--black);
            height: 100%;
            text-align: center;
            font-size: 0.95rem;
            font-weight: 500;
            margin-left: 15px;
            display: flex;
            align-items: center;
            // justify-content: center;
          }

          .checkbox-columns {
            padding-top: 5px;
            height: 50px;
            border-left: 1px solid var(--black);
            border-right: 1px solid var(--black);
          }
        }

        .focus {
          background: var(--text);
        }
      }
    }
  }
}

.content-form {
  .header-form {
    padding: 18px 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--secondary);

    legend {
      font-size: 1.05rem;
      font-weight: 600;
      color: var(--white);
    }
  }

  .form-painel {
    .content-block-form {
      display: grid;
      grid-template-columns: 100%;

      .block-form-inputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        margin: 0 6px;

        label {
          margin-bottom: 6px;
        }

        input {
          padding: 6px 12px;
          border: 1px solid var(--lineGray);
          border-radius: 4px;
          font-size: 0.85rem;
          -webkit-appearance: none;
          outline: none;
          box-shadow: 1px 1px #888888;
        }

        input:focus {
          background: #ecebeb;
        }

        input::placeholder {
          font-style: italic;
        }

        input::-webkit-input-placeholder {
          padding-left: 0;
        }
      }
    }

    .form-button-painel {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      button {
        width: 135px;
        height: 40px;
        color: var(--secondary);
        border: 1px solid var(--secondary);
        border-radius: 50px;
        margin-right: 12px;
        padding: 12px;
        // -webkit-appearance: none;
        // outline: none;
        transition: filter 0.2s;
        display: flex;
        align-items: center;
        background: var(--white);
        justify-content: center;
      }

      button:hover {
        filter: brightness(0.9);
      }

      button+button {
        padding-right: 12px;
      }

      button:last-child:hover {
        filter: brightness(0.9);
      }

      button:last-child {
        color: var(--white);

        background: var(--secondary);
        border: 1px solid var(--lineGray);
      }
    }
  }
}

.content-form-users {
  .header-form {
    padding: 18px 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--secondary);

    legend {
      font-size: 1.05rem;
      font-weight: 600;
      color: var(--white);
    }
  }

  .controller-users {
    form {
      .block-form-inputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        margin: 0 6px;

        .warning {
          border: 1px solid var(--warning);
        }

        label {
          margin-bottom: 6px;
        }

        input {
          padding: 6px 12px;
          border: 1px solid var(--lineGray);
          border-radius: 4px;
          font-size: 0.85rem;
          -webkit-appearance: none;
          outline: none;
          box-shadow: 1px 1px #888888;
        }

        input:focus {
          background: #ecebeb;
        }

        input::placeholder {
          font-style: italic;
        }

        input::-webkit-input-placeholder {
          padding-left: 0;
        }

        button {
          height: 40px;
          color: var(--secondary);
          border-radius: 50px;
          margin-top: 12px;
          padding: 12px;
          // -webkit-appearance: none;
          // outline: none;
          transition: filter 0.2s;
          display: flex;
          align-items: center;
          background: var(--white);
          justify-content: center;
        }

        button:hover {
          filter: brightness(0.9);
        }
      }

      .form-button-painel {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;

        button {
          width: 135px;
          height: 40px;
          color: var(--secondary);
          border: 1px solid var(--secondary);
          border-radius: 50px;
          margin-right: 12px;
          padding: 12px;
          // -webkit-appearance: none;
          // outline: none;
          transition: filter 0.2s;
          display: flex;
          align-items: center;
          background: var(--white);
          justify-content: center;
        }

        button:hover {
          filter: brightness(0.9);
        }

        button+button {
          padding-right: 12px;
        }

        button:last-child:hover {
          filter: brightness(0.9);
        }

        button:last-child {
          color: var(--white);

          background: var(--secondary);
          border: 1px solid var(--lineGray);
        }
      }
    }

    .controller-users-titles {
      display: grid;
      grid-template-columns: 20% 25% 25% 30%;
      gap: 12px;
      padding: 14px 0 14px 24px;

      legend {
        font-size: 1.05rem;
        color: var(--secondary);
        font-weight: 600;
      }

      span {
        margin: 0;
        padding: 0;
      }

      .label-active {
        background: var(--primary);
        color: var(--secondary);
      }

      label {
        justify-content: center;
        align-items: center;
        width: 165px;
        // height: 15px;
        border-radius: 32px;
        border: 1px solid var(--lineGray);
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        background: var(--white);
        color: var(--black);
        font-size: 0.75rem;
        display: flex;
        font-weight: 500;
        align-items: center;
        justify-content: flex-start;
        padding: 4px 18px;
        // margin: 12px 0;
        cursor: pointer;
        position: relative;

        input {
          width: 0.9em;
          height: 0.9em;
          background-color: white;
          border-radius: 50%;
          vertical-align: middle;
          border: 1px solid var(--lineGray);
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
          margin-right: 12px;
        }

        img {
          position: absolute;
          width: 1.2em;
          height: 1.2em;
          cursor: pointer;
          // left: 140px;
        }
      }

      label+label {
        margin: 0 12px;
      }
    }
  }
}

[role="tooltip"].mypopup-menu-content {
  width: 135px;
}

#painel-users-label {
  transition: filter 0.2s;
}

#painel-users-label:hover {
  width: 40px;
  height: 40px;
  filter: brightness(0.9);
}

.block-menu-painel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  legend {
    cursor: pointer;
    font-weight: 600;
    font-size: 0.95rem;
    transition: filter 0.2s;
  }

  legend:hover {
    font-size: 1.05rem;
    filter: brightness(0.9);
  }
}