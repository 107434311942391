.footer {
    background: var(--secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 50px;
    flex: 1;

    #imgLogoFooter {
        width: 125px;
        height: 55px;
    }

    span {
        font-size: 0.85rem;
        color: var(--primary);
        font-weight: 400;
    }

    label {
        font-size: 0.85rem;
        color: var(--primary);

        display: flex;
        align-items: center;
        font-weight: 400;
    }

    legend {
        font-size: 0.85rem;
        color: var(--primary);
        margin-bottom: 12px;
        font-weight: bold;
    }

    .moreInfos {
        // max-width: 1000px;
        display: flex;

        // flex-direction: row;
        // // align-items: center;
        justify-content: space-between;
        gap: 65px;
        // display: fixed;

        // display: grid;
        // grid-template-columns: 2fr 1fr 1.5fr 1fr 1fr;

        .address {
            .blockAddress {
                display: flex;
                flex-direction: column;
            }

            .blockAddress+.blockAddress {
                margin-top: 25px;
            }
        }

        .contact {

            // padding-left: 34px;
            // border-left: 1px solid var(--primary);
            // border-left: 1px solid var(--primary);
            .blockContact+.blockContact {
                margin-top: 25px;
            }

            .blockContact {
                display: flex;
                flex-direction: column;
            }
        }

        .midia {
            .blockMidia+.blockMidia {
                margin-top: 25px;
            }

            .blockMidia {
                // margin: 1em auto;
                position: relative;
                padding: 0;
                right: 0;
                display: flex;
                justify-content: flex-end;
                width: 100px;

                // right: -100px;
                // width: 100%;
                // height: 100%;
                img {
                    position: absolute;
                    right: 80px;
                    margin-right: 20px;
                    top: 35px;
                    z-index: 100;
                    background-color: blue;
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }

                input {
                    width: 180px;
                    border: none;
                    border-bottom: 1px solid var(--black);
                    padding: 6px 12px;
                    text-align: start;
                    background: transparent;
                    -webkit-appearance: none;
                    outline: none;
                    outline-color: var(--primary);
                    font-size: 0.95rem;
                    font-style: italic;
                    color: var(--lineGray);
                    // position: relative;
                }

                input::placeholder {
                    padding: 0;
                    color: var(--lineGray);
                }

                display: flex;
                flex-direction: column;

                label {
                    margin-bottom: 6px;
                }
            }
        }
    }
}