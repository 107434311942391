.wppPhoneInputContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    input {
        margin-top: 0.5rem;
        width: 100%;
        padding: 6px 12px;
        border: 1px solid var(--lineGray);
        border-radius: 4px;
        font-size: 0.85rem;
        -webkit-appearance: none;
        outline: none;
        box-shadow: 1px 1px #888888;
    }
}

.occurrence {

    // width: 500px;
    .header-occurrence {
        width: 100%;
        padding: 0;
        margin-bottom: 8px;
        padding: 12px;
        background: var(--secondary);

        display: flex;
        justify-content: space-between;
        color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        span {
            color: var(--white);
        }
    }

    .form-occurrence {
        // .blockOcurrenceCheckAndName {
        padding: 0 12px;

        //     display: flex;
        //     align-items: center;
        //     justify-content: space-between;
        .nameOccurence {
            display: flex;
            padding: 0 12px;

            flex-direction: column;
            // align-items: center;
            justify-content: flex-end;

            legend {
                font-size: 0.85rem;
                margin-bottom: 6px;
            }

            input {
                padding: 6px 12px;
                border: 1px solid var(--lineGray);
                border-radius: 4px;
                font-size: 0.85rem;
                -webkit-appearance: none;
                outline: none;
                box-shadow: 1px 1px #888888;
            }

            input::placeholder {
                font-style: italic;
            }

            input::-webkit-input-placeholder {
                padding-left: 0;
            }
        }

        .checkbox-occurrence {
            display: flex;
            justify-content: center;
            align-items: center;
            // width: 250px;

            .label-active {
                background: var(--primary);
                color: var(--secondary);
            }

            label {
                justify-content: center;
                align-items: center;
                width: 325px;
                height: 35px;
                border-radius: 32px;
                border: 1px solid var(--lineGray);
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                background: var(--white);
                color: var(--black);
                font-size: 0.90rem;
                display: flex;
                font-weight: 500;
                align-items: center;
                justify-content: flex-start;
                padding: 22px 18px;
                margin: 12px 0;
                cursor: pointer;
                position: relative;

                input {
                    width: 0.9em;
                    height: 0.9em;
                    background-color: white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid var(--lineGray);
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    margin-right: 12px;
                }

                img {
                    position: absolute;
                    width: 1.1em;
                    height: 1.1em;
                    cursor: pointer;
                    // left: 140px;
                }
            }

            label+label {
                margin: 0 12px;
            }
        }

        // }
        .block-occurrence {
            gap: 8px;
            display: flex;
            flex-direction: column;
            padding: 16px;

            textarea {
                padding: 6px 12px;
                width: 100%;
                height: auto;
                resize: none;
                -webkit-appearance: none;
                outline: none;
                border: 1px solid var(--lineGray);

                font-size: 0.85rem;
                text-align: justify;

                border-radius: 8px;
                box-shadow: 1px 1px #888888;
            }

            textarea::placeholder {
                font-style: italic;
            }

            textarea::-webkit-input-placeholder {
                padding-left: 0;
            }
        }

        .button-form {
            margin: 20px 0 15px 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button {
                width: 135px;
                height: 35px;
                background: var(--white);
                border: 1px solid var(--secondary);
                color: var(--black);
                border-radius: 50px;
                margin-right: 12px;
                padding: 12px;
                -webkit-appearance: none;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            button+button {
                padding-right: 12px;
            }

            button:last-child {
                background: var(--secondary);
                color: var(--white);
                border: 1px solid var(--lineGray);
            }
        }
    }

    .allOccurence {
        overflow-x: scroll;
        width: 100%;
    }
}

@media (max-width: 1135px) {
    .blockOcurrenceCheckAndName {
        display: flex;
        flex-direction: column;
    }
}