.alloccurrence {
    width: auto;

    .photoCheckBox {}

    .codInput {
        padding: 2px 12px;
        width: 90%;
        height: 50%;
        resize: none;
        -webkit-appearance: none;
        outline: none;
        border: 1px solid var(--lineGray);
        font-size: 0.7rem;
        text-align: justify;
        border-radius: 8px;
        box-shadow: 1px 1px #888888;
        margin-top: 15px;
    }

    .header-alloccurrence {
        // width: 100%;
        padding: 0;
        margin-bottom: 8px;
        padding: 12px;
        background: var(--secondary);

        display: flex;
        justify-content: space-between;
        color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        span {
            color: var(--white);
        }
    }

    .main-alloccurrence {
        padding: 0 12px;
        // width: 700px;
        max-height: 400px;
        overflow-y: scroll;

        legend {
            color: var(--secondary);
            font-weight: bold;
        }

        .titlesOccurrence {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }

        .blockAllOcurrenceOnly {
            width: 100%;
            display: grid;
            // place-content: center;
            grid-template-columns: 14% 14% 5% 23% 20% 10% 14%;
            // justify-content: space-between;
            margin: 8px 0;
            border-bottom: 1px solid var(--secondary);

            .blockCheckbox {
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;

                label {
                    margin-bottom: 15px;
                }
            }



            .label-active {
                background: var(--primary);
                color: var(--black);
            }

            .changeCard {
                legend {
                    margin-bottom: 10px;
                }

                input {
                    // margin-top: 10px;
                    padding: 6px 6px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    width: 100px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                    // margin: 0;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }
            }

            textarea {
                padding: 6px 12px;
                width: 90%;
                height: 50%;
                resize: none;
                // -webkit-appearance: none;
                // outline: none;
                // border: 1px solid var(--lineGray);
                font-size: 0.7rem;
                text-align: justify;
                // border-radius: 8px;
                // box-shadow: 1px 1px #888888;
                margin-top: 15px;
            }

            textarea::placeholder {
                font-style: italic;
            }

            textarea::-webkit-input-placeholder {
                padding-left: 0;
            }

            label {
                width: 45px;
                height: 15px;
                border-radius: 32px;
                border: 1px solid var(--lineGray);
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                background: var(--white);
                color: var(--lineGray);
                display: flex;
                font-weight: 600;
                align-items: center;
                justify-content: center;
                padding: 12px 6px;
                margin: 12px 0;
                position: relative;

                input {
                    width: 1.1em;
                    height: 1.1em;
                    background-color: white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid var(--lineGray);
                    -webkit-appearance: none;
                    outline: none;
                    cursor: default;
                    // margin-right: 12px;
                }

                img {
                    position: absolute;
                    width: 1.1em;
                    height: 1.1em;
                    cursor: default;
                    // left: 140px;
                }
            }

            legend {
                font-size: 0.95rem;
                font-weight: 500;
            }

            span {
                color: var(--black);
                font-size: 0.9rem;
            }

            .checkbox {
                display: flex;
                align-items: center;
                flex-direction: column;
            }

            .infosAllOcurrenceOnly {
                background-color: transparent;
                display: flex;
                flex-direction: column;
                padding-right: 10px;

                legend {
                    margin: 0;
                }

                label {
                    // width: 45px;
                    height: auto;
                    display: flex;
                    // align-items: flex-start;\
                    justify-content: flex-start;
                    padding: 0;
                    // width: 250px;
                    // height: 55px;
                    // overflow-y: scroll;
                    outline: none;
                    border: none;
                    color: var(--black);
                    font-size: 0.75rem;
                    // text-align: justify;

                    border-radius: 8px;
                    box-shadow: none;
                }

                label::placeholder {
                    font-style: italic;
                }

                label::-webkit-input-placeholder {
                    padding-left: 0;
                }
            }

            .blockIconButtonsOcurrences {
                display: flex;
                align-items: center;
                justify-content: center;
                // padding-left: 30px;
                // justifyContent: "center",
                font-size: 1.2rem;

                div {
                    display: flex;
                    gap: 10px
                }
            }

            .blockIconButtonsOcurrences:first-child {
                z-index: 999;
                // display: flex;
                // flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
            }
        }
    }
}

.sendButtonOccurences {
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    background: var(--secondary);
    color: var(--white);
    font-size: 0.85rem;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--primary);
        filter: opacity(0.8)
    }
}

.cancelButtonOcurrences {
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    background: var(--white);
    color: var(--blackText);
    border: 2px solid var(--lineGray);
    font-size: 0.85rem;
    transition: filter 0.2s;
    cursor: pointer;

    &:hover {
        filter: brightness(0.9);
    }
}