.container {
  background: var(--white);
  // opacity: 0.2;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding-left: 20px;
  // border: 1px solid var(--textGray);

  section {
    display: grid;
    grid-template-columns: 0.40fr 0.85fr 1fr 0.2fr 0.2fr;
    width: 100%;
    padding-right: 20px;

    p {
      display: flex;
      align-items: center;
      // justify-content: center;
      margin-top: 0;
      font-size: 0.8rem;
    }
  }
}

.blockIconButtons {
  display: flex;
  // flex-direction: column;
  align-items: center;
  // justify-content: center;
  // margin-left: 10px;
}

.blockIconButtons:first-child {
  z-index: 999;
  display: flex;
  // flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 20px;
}

.buttonsContainer {
  padding-right: 0;

  .sendButtonAccounts {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    background: var(--secondary);
    color: var(--white);
    font-size: 0.65rem;
    transition: background-color 0.2s;
    cursor: pointer;
    width: 70px;

    &:hover {
      background-color: var(--primary);
      filter: opacity(0.8)
    }
  }

  .cancelButtonAccounts {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    background: var(--white);
    color: var(--blackText);
    border: 1px solid var(--lineGray);
    font-size: 0.65rem;
    transition: filter 0.2s;
    cursor: pointer;
    width: 70px;


    &:hover {
      filter: brightness(0.9);
    }
  }
}