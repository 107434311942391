.popupCreateRomaneio {
    // margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // background: var(--secondary);
    border-radius: 8px;

    .header {
        width: 100%;
        padding: 0;
        margin-bottom: 8px;
        padding: 12px;
        background: var(--secondary);

        display: flex;
        justify-content: space-between;
        color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        span {
            color: var(--white);
        }
    }

    .myFormClass {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        padding: 12px;
        background: var(--white);
        width: 100%;

        .documentsXML {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            span {
                font-size: 0.95rem;
                font-weight: 500;
                color: var(--secondary);
                padding: 0 0 0 6px;
            }

            .importXMLButtonContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            label {
                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 4px;
                }

                width: 180px;
                height: 40px;
                background: var(--secondary);
                color: var(--white);
                border-radius: 50px;
                margin-right: 12px;
                margin-bottom: 30px;
                padding: 12px;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
                font-size: 0.85rem;

                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    width: 150px;
                    position: absolute;
                    cursor: pointer;
                    display: none;
                    // opacity: 0;
                }
            }

            .document {
                background: var(--lineGray);
                width: 100%;
                padding: 6px 4px;
                border-radius: 4px;
                display: flex;
                justify-content: space-between;

                input {
                    background: var(--white);
                    border: none;
                    margin: 0;
                    padding: 0 0 0 6px;
                    height: 24px;
                    width: 93%;
                    font-size: 0.95rem;
                    font-weight: 500;
                    color: var(--secondary);
                }

                .blockEdit {
                    img {
                        cursor: pointer;
                        width: 18px;
                        height: 18px;
                        margin: 0 5px;
                    }
                }
            }
        }

        legend {
            margin-bottom: 12px;
            font-size: 0.95rem;
            font-weight: bold;
            color: var(--secondary);
        }

        .input-form {
            display: grid;
            grid-template-columns: 50% 25% 25%;

            .blockInput {
                gap: 8px;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                input {
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }
            }

            .blockInput+.blockInput {
                margin-left: 12px;
            }
        }

        .input-form+.input-form {
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            textarea {
                padding: 6px 12px;
                width: 100%;
                height: 90px;
                resize: none;
                -webkit-appearance: none;
                outline: none;
                border: 1px solid var(--lineGray);

                font-size: 0.85rem;
                text-align: justify;

                border-radius: 8px;
                box-shadow: 1px 1px #888888;
            }

            textarea::placeholder {
                font-style: italic;
            }

            textarea::-webkit-input-placeholder {
                padding-left: 0;
            }
        }

        .button-form {
            margin: 20px 0 15px 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button {
                width: 165px;
                height: 45px;
                background: var(--white);
                color: var(--black);
                border-radius: 50px;
                margin-right: 12px;
                padding: 12px;
                -webkit-appearance: none;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            button+button {
                padding-right: 12px;
            }

            button:last-child {
                background: var(--secondary);
                color: var(--white);
                border: 1px solid var(--lineGray);
            }
        }
    }
}

#buttonOpenPopup {
    opacity: 1;
    outline: none;
    border: none;
    background: transparent;
    // background-color: var(--background);

}

@media (max-width: 520px) {
    .popupCreateRomaneio {
        form {
            .documentsXML {
                label {
                    width: 240px;
                    font-size: 0.85rem;
                }
            }
        }
    }
}