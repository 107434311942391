.popupCreateRomaneio {
    // margin: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // background: var(--secondary);
    border-radius: 8px;

    .headerRomaneio {
        width: 100%;
        padding: 0;
        margin-bottom: 8px;
        padding: 12px;
        background: var(--secondary);

        display: flex;
        justify-content: space-between;
        color: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        span {
            color: var(--white);
        }
    }

    .formClass {
        height: 700px;
    }

    form {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow-x: scroll;
        padding: 12px;
        background: var(--white);
        width: 100%;

        legend {
            margin-bottom: 12px;
            font-size: 0.95rem;
            font-weight: bold;
            color: var(--secondary);
        }

        .input-formRomaneio {
            display: grid;
            grid-template-columns: 44% 18% 16% 22%;
            justify-content: space-between;

            .blockInputRomaneio {
                gap: 2px;
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                button {
                    margin-top: 4px;
                    width: 180px;
                    border-radius: 8px;
                    background: white;
                    padding: 4px 12px;
                    border: 1px solid var(--secondary);
                    transition: 0.2s filter;
                }

                button:hover {
                    filter: brightness(0.9);
                }

                #CPFlabel,
                input {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 0.85rem;
                    border: 1px solid var(--lineGray);
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }

                #CPFlabel {
                    padding: 6px;
                    color: #888888;
                    font-style: italic;
                }

                input {
                    -webkit-appearance: none;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }
            }

            .blockInputRomaneio+.blockInputRomaneio {
                margin-left: 12px;
            }
        }

        .input-formRomaneio+.input-formRomaneio {
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            textarea {
                padding: 6px 12px;
                width: 100%;
                height: 90px;
                resize: none;
                -webkit-appearance: none;
                outline: none;
                border: 1px solid var(--lineGray);

                font-size: 0.85rem;
                text-align: justify;

                border-radius: 8px;
                box-shadow: 1px 1px #888888;
            }

            textarea::placeholder {
                font-style: italic;
            }

            textarea::-webkit-input-placeholder {
                padding-left: 0;
            }
        }

        .allCards {
            display: flex;
            flex-direction: column;

            .gridIntoRomaneios {
                margin: 20px 0;
                display: flex;
                flex-direction: column;

                .cards {
                    gap: 12px;
                    display: flex;
                    overflow-x: scroll;
                }
            }

            .gridIntoDocuments {
                margin: 20px 0;
                display: flex;
                flex-direction: column;

                .cards {
                    display: flex;
                    overflow-x: scroll;
                    gap: 12px;
                }
            }
        }

        .button-form {
            margin: 20px 0 15px 0;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            button {
                transition: all 0.2s;
                width: 165px;
                height: 45px;
                // background: var(--secondary);
                color: var(--secondary);
                border: 1px solid var(--lineGray);

                border-radius: 50px;
                margin-right: 12px;
                padding: 12px;
                -webkit-appearance: none;
                outline: none;

                display: flex;
                align-items: center;
                justify-content: center;

                &+button {
                    background: var(--secondary);
                    color: var(--white);
                    border: 1px solid var(--lineGray);

                    &:hover {
                        background: var(--primary);
                    }
                }
            }
        }
    }
}

#buttonOpenPopup {
    opacity: 1;
    outline: none;
    border: none;
    background: transparent;
}

[role="tooltip"].styledPopupRomaneio-content {
    width: 120px;
}