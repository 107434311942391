.content-resetPassword {
    .header-form {
        padding: 18px 12px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: var(--secondary);

        legend {
            font-size: 1.05rem;
            font-weight: 600;
            color: var(--white);
        }
    }

    form {
        .block-resetPassword {
            .block-form-inputs {
                display: flex;
                flex-direction: column;
                margin: 0 6px;

                .warning {
                    border: 1px solid var(--warning);
                }

                label {
                    margin-bottom: 12px;
                }

                input {
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                    margin-bottom: 12px;
                }

                input:focus {
                    background: #ecebeb;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }
            }
        }

        .block-button {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;

            button {
                width: 135px;
                height: 40px;
                color: var(--secondary);
                border: 1px solid var(--secondary);
                border-radius: 50px;
                padding: 12px;
                margin: 0 4px;
                // -webkit-appearance: none;
                // outline: none;
                transition: filter 0.2s;
                display: flex;
                align-items: center;
                background: var(--white);
                justify-content: center;
            }

            button:hover {
                filter: brightness(0.9);
            }

            button+button {
                padding-right: 12px;
            }

            button:last-child:hover {
                filter: brightness(0.9);
            }

            button:last-child {
                color: var(--white);

                background: var(--secondary);
                border: 1px solid var(--lineGray);
            }
        }
    }
}