.btnDocuments {
    width: 235px;
    height: 45px;
    // width: auto;
    // padding: 0 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 80px;
    border: 1px solid var(--secondary);

    margin-right: 12px;

    img {
        height: 9px;
        width: 9px;
        margin-right: 4px;
    }

    span {
        color: var(--secondary);
        font-size: 0.75rem;
    }
}

.btnDocumentsSelect {
    background: var(--secondary);

    span {
        color: var(--white);
    }
}

@media (max-width: 560px) {
    .btnDocuments {
        margin-bottom: 12px;
    }
}