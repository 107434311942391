.header {
    display: grid;
    grid-template-columns: 1.2fr 1.5fr 1.2fr;
    align-items: center;
    background: var(--headerClient);
    flex: 1;
    height: 55px;
    width: 100%;

    .logo {
        flex: 1;
        display: flex;
        justify-content: center;

        img {
            width: 175px;
            height: 35px;
        }
    }


    .infos {
        display: flex;
        justify-content: center;
        flex: 1;

        span {
            font-size: 0.95rem;
            font-weight: 400;
            color: var(--black);
            cursor: pointer;
        }

        span:last-child {
            margin-right: 12px;
        }

        span:first-child {
            margin-right: 24px;
        }

        span+span {
            margin-right: 24px;
        }
    }

    .zoneClient {
        display: flex;
        justify-content: center;
        flex: 1;

        label {
            padding: 8px 18px;
            background: var(--secondary);
            border-radius: 32px;

            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 0.75rem;
                color: var(--headerClient);
            }
        }
    }
}