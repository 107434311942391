.mainCardOccurrence {
    border: 1px solid var(--warning);
}

.mainCardDefault {
    border: 1px solid var(--lineGray);
}

.mainCard {
    width: 220px;
    min-height: 420px;
    border-radius: 8px;
    background: var(--white);
    // margin-bottom: 16px;
    // padding: 0 0 12px 0;
    display: grid;
    // justify-content: space-between;
    cursor: pointer;

    .headerCard {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 12px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .blockRight {
            display: flex;
            flex-direction: column;

            label {
                font-size: 1rem;
                color: var(--white);
                font-weight: bold;
            }

            span {
                color: var(--white);
                font-size: 0.65rem;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                line-height: 16px;
                max-height: 32px;

                /* The number of lines to be displayed */
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                // padding: 0;
                // margin: 0;
            }
        }

        .blockLeft {
            display: flex;
            align-items: flex-start;
            position: relative;

            input {
                width: 1.1em;
                height: 1.1em;
                background-color: white;
                border-radius: 50%;
                vertical-align: middle;
                border: 1px solid #ddd;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
                position: relative;
            }

            img {
                position: absolute;
                cursor: pointer;
            }
        }
    }

    .headerCardDelivery {
        background: var(--delivery);
    }

    .headerCardTransfer {
        background: var(--transfer);
    }

    .headerCardCollect {
        background: var(--collect);
    }

    .headerCardFinish {
        background: var(--finish);
    }

    .headerCardWarning {
        background: var(--warning);
    }

    .headerCardTraveling {
        background: var(--traveling);
    }

    .codCollect {
        padding: 0 8px;
        margin: 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.75rem;
            color: var(--textGrayCard);
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // line-height: 16px;
            // max-height: 32px;

            // /* The number of lines to be displayed */
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
        }

        .statusCard {
            display: flex;
            align-items: center;
            justify-content: center;

            .imageStatus {
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    padding-bottom: 3px;
                    margin: 0;
                }
            }

            img {
                margin-right: 4px;
            }

            p {
                font-size: 0.75rem;
                color: var(--textGrayCard);
            }
        }
    }

    .blockInfo {
        padding: 0 8px;

        span {
            font-size: 1rem;
            color: var(--secondary);
            margin-bottom: 2px;
        }

        p {
            color: var(--textGray);
            font-size: 0.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 16px;
            max-height: 32px;

            /* The number of lines to be displayed */
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .blockInfoTime {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        height: 40px;

        span {
            font-size: 0.75rem;
            color: var(--textGray);

            img {
                margin-right: 12px;
            }
        }
    }

    .progress {
        display: flex;
        flex-direction: column;
        // align-items: center;
        gap: 0.1rem;
        font-size: 0.875rem;
        padding: 0 8px;
        margin-top: 8px;

        span {
            font-size: 0.75rem;
            color: var(--textGray);
        }

        .slider {
            flex: 1;
        }
    }
}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

@media (max-width: 500px) {
    .mainCard {
        width: 140px;
        height: 340px;
    }
}

[role="tooltip"].mypopup-content {
    width: 125px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;

    .changeCard {
        // position: absolute;
        // background: var(--white);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 8px 6px;
        // right: 0;
        // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        // color: var(--black);
        // gap: 6px;

        span {
            font-size: 0.95rem;
            cursor: pointer;
            transition: filter 0.2;
            margin-top: 6px;
        }

        span:hover {
            filter: brightness(0.1);
        }
    }
}

.popup-content {
    margin: auto;
    z-index: 10;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
}