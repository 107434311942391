.companyGroupContainer {
  min-width: 200px;
  margin-right: 20px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.chatAlertPopup {
  background: var(--white);
  border-radius: 8px;
  color: var(--black);
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  top: -14px;
  right: -10px;
  z-index: 1000;

  #buttonOpenPopupNotification {
    opacity: 1;
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    border-radius: 8px;
  }

  >span {
    font-size: 1.15rem;
    font-weight: 600;
    color: var(--secondary);
    margin-bottom: 12px;
    padding: 6px 12px 4px 16px;
  }

  .blockMsg {
    padding: 12px 20px;

    display: flex;
    // flex-direction: column;

    .msgAlert {
      display: flex;
      flex-direction: column;
      margin-left: 6px;
      text-align: start;

      span {
        margin-bottom: 4px;
      }

      span:first-child {
        font-size: 1rem;
        font-weight: 400;
      }

      span:last-child {
        font-size: 0.95rem;
      }
    }
  }

  .blockMsg-focus {
    border-radius: 8px;

    background: #f3f3f3;
  }
}

.headerPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  height: 62px;
  width: 100%;

  padding: 12px 0;

  background: var(--secondary);

  #buttonOpenPopup {
    .messageIcon {
      color: #fff;
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }

  .myChatpopupNotificattion {
    width: 1000px;
  }

  .firstBlock {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: 12px;
    // position: relative;

    img:first-child {
      margin-right: 8rem;
      // padding-left: 4rem;
      object-fit: cover;
      cursor: pointer;
      max-width: 320px;
      resize: stretch;
      height: 35px;
    }

    // img:last-child {
    //     width: 38px;
    //     height: 38px;
    //     cursor: pointer;
    // }
  }

  .secondBlock {
    display: flex;
    justify-content: center;
    align-items: center;

    .contentInfoUser {
      position: relative;

      .infosUser {
        background: var(--white);
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        padding: 6px;
        position: absolute;
        border-radius: 8px;
        width: 225px;
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        top: 12px;
        right: 50px;

        span {
          font-weight: 400;
          color: var(--secondary);
        }
      }
    }

    >span {
      margin-right: 6px;
      color: var(--text);
      font-size: 1.05rem;
    }

    img {
      margin-right: 3rem;
      cursor: pointer;
    }

    img:last-child {
      margin-right: 12px;
    }

    .Notification {
      width: auto;
      position: relative;
    }

    .Alert {
      height: 10px;
      width: 10px;
      background: var(--notification);
      border-radius: 50%;
      cursor: pointer;

      top: 60%;
      left: 35%;

      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--white);
      font-weight: bold;
      font-size: 14px;
      align-items: center;
    }

    p {
      color: #000000;
    }

    .menuRight {
      display: flex;
      position: relative;
      margin-right: 12px;

      .circle {
        height: 28px;
        width: 28px;
        background: var(--primary);
        border-radius: 50%;
        font-size: 14px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--black);
        font-weight: bold;
      }

      img {
        padding: 0;
        margin: 0 0 0 4px;
      }
    }
  }
}

@media (max-width: 560px) {
  .headerPage {
    height: 120px;
    padding: 12px 6px;

    align-items: flex-start;
    flex-direction: column;

    .firstBlock {
      justify-content: space-between;
      width: 100%;
    }

    .secondBlock {
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;

      .contentInfoUser {
        width: 20px;
        z-index: 100;
        right: -60px;
        // .infosUser {
        // }
      }

      span {
        padding-right: 12px;
        color: var(--text);
        font-size: 0.75rem;
      }

      .Alert {
        left: 10px;
        padding: 4px;
        font-size: 14px;
      }

      .circle {
        padding: 2px;
      }
    }
  }
}

[role="tooltip"].mypopupNotificattion-content {
  position: absolute;
  width: 15px;
  height: auto;
  top: 0;




  .alertPopup {
    position: absolute;
    background: var(--white);
    border-radius: 8px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    color: var(--black);
    width: 320px;
    max-height: 450px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    top: -14px;
    right: -10px;
    z-index: 1000;



    #buttonOpenPopupNotification {
      opacity: 1;
      outline: none;
      border: none;
      width: 100%;
      background: transparent;
      border-radius: 8px;
    }

    >span {
      font-size: 1.15rem;
      font-weight: 600;
      color: var(--secondary);
      margin-bottom: 12px;
      padding: 6px 12px 4px 16px;
    }

    .blockMsg {
      padding: 0 12px 8px 12px;

      display: flex;
      // flex-direction: column;

      .msgAlert {
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        margin-bottom: 12px;
        text-align: start;

        span {
          margin-bottom: 4px;
        }

        span:first-child {
          font-size: 1rem;
          font-weight: 400;
        }

        span:last-child {
          font-size: 0.95rem;
        }
      }
    }

    .blockMsg-focus {
      border-radius: 8px;

      background: #f3f3f3;
    }
  }

  .chatAlertPopup {
    width: 420px;
  }
}

[role="tooltip"].infoUserCpfCnpj-content {
  // position: absolute;
  // width: 15px;
  // height: auto;
  // top: 0;

  .infosUser {
    background: var(--white);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 6px;
    position: absolute;
    border-radius: 8px;
    width: 225px;
    height: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: -8px;
    right: 0px;

    span {
      font-weight: 400;
      color: var(--secondary);
    }
  }
}

[role="tooltip"].mypopupHeader-content {
  position: absolute;
  width: 15px;
  height: auto;
  top: 0;

  .content-menu-topRight {
    position: absolute;
    width: 165px;
    top: -14px;
    right: 0px;
    padding: 18px 12px;
    background: var(--white);
    border-radius: 8px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 999;
    margin-top: 12px;

    .blockRightMenu+.blockRightMenu {
      margin-top: 4px;
    }

    .blockRightMenu {
      p {
        text-align: center;
        font-weight: bold;
        margin: 0 0 5px;
        font-size: 0.8rem;
      }

      .customArea {
        background: var(--white);
        position: absolute;
        width: 315px;
        height: 195px;
        z-index: 1000;
        top: 85px;
        right: 115px;
        bottom: 0;
        padding: 16px 16px;
        border-radius: 8px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);

        span {
          font-size: 1.05rem;
          color: var(--secondary);
          margin-right: 20px;
        }

        #spanEmoji {
          pointer-events: none;
        }

        .closeIcon {
          position: absolute;
          // color: red;
          top: 4px;
          right: 6px;
        }

        .changeWallpaper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 12px 0px;

          .input-block {
            // width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .close-images {
              position: relative;
              width: 54px;
              height: 54px;

              img {
                width: 54px;
                height: 54px;
                border-radius: 8px;
              }
            }

            .close-images #icon {
              position: absolute;
              background-color: #d3d3d3;
              border-radius: 8px;
              right: 0px;
              top: 0;
            }

            span {
              margin-right: 6px;
              font-size: 0.95rem;
              color: var(--secondary);
              // width: 60px;
            }

            input {
              display: none;
            }

            .new-image {
              height: 36px;
              width: 36px;
              background: #f5f8fa;
              border: 1px dashed #96d2f0;
              border-radius: 12px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              // margin-left: 12px;
            }
          }
        }

        .saveForm {
          display: flex;
          justify-content: flex-end;
          margin-top: 12px;

          button {
            background: #3cdc8c;
            color: var(--white);
            border-radius: 20px;
            padding: 6px 12px;
            border: none;
            margin-right: 6px;
            transition: filter 0.2;
          }

          button:last-child {
            margin: 0;
          }

          button:first-child {
            background: var(--white);
            color: var(--black);
            border: 1px solid var(--lineGray);
          }

          button:hover {
            filter: brightness(0.9);
          }

          button:first-child:hover {
            filter: brightness(0.975);
          }
        }

        .changeTheme {

          // input {
          //     background: var(--white);
          //     color: var(--secondary);
          //     border-radius: 8px;
          //     margin-top: 6px;
          //     // border: 1px solid #;
          //     padding: 4px 6px;
          // }
          .pallete {
            border-radius: 4px;
            margin: 0 10px;
            width: 35px;
            height: 15px;
            border: 2px solid rgb(54, 52, 52);
            cursor: pointer;
          }

          span {
            font-size: 0.95rem;
            display: flex;
            align-items: center;
            padding-right: 12px;
          }

          .iconClose {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: flex-end;
            padding-right: 12px;
            right: 100px;
          }
        }
      }

      span,
      a {
        font-size: 0.95rem;
        // font-size: 0.95rem;
        cursor: pointer;
        transition: filter 0.2;
      }

      a,
      span:hover {
        filter: brightness(0.4);
      }

      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 520px) {
  [role="tooltip"].mypopupHeader-content {
    position: absolute;
    width: 15px;
    height: auto;
    top: 0;

    .content-menu-topRight {
      position: absolute;
      width: 115px;
      // top: -14px;
      // right: -10px;
      padding: 18px 12px;
      background: var(--white);
      border-radius: 8px;
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
      z-index: 999;
      margin-top: 12px;

      .blockRightMenu+.blockRightMenu {
        margin-top: 4px;
      }

      .blockRightMenu {
        .customArea {
          background: var(--white);
          position: absolute;
          width: 315px;
          height: 195px;
          z-index: 1000;
          top: 85px;
          right: 115px;
          bottom: 0;
          padding: 16px 16px;
          border-radius: 8px;
          box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);

          span {
            font-size: 1.05rem;
            color: var(--secondary);
            margin-right: 20px;
          }

          #spanEmoji {
            pointer-events: none;
          }

          .closeIcon {
            position: absolute;
            // color: red;
            top: 4px;
            right: 6px;
          }

          .changeWallpaper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 12px 0px;

            .input-block {
              // width: auto;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              .close-images {
                position: relative;
                width: 54px;
                height: 54px;

                img {
                  width: 54px;
                  height: 54px;
                  border-radius: 8px;
                }
              }

              .close-images #icon {
                position: absolute;
                background-color: #d3d3d3;
                border-radius: 8px;
                right: 0px;
                top: 0;
              }

              span {
                margin-right: 6px;
                font-size: 0.95rem;
                color: var(--secondary);
                // width: 60px;
              }

              input {
                display: none;
              }

              .new-image {
                height: 36px;
                width: 36px;
                background: #f5f8fa;
                border: 1px dashed #96d2f0;
                border-radius: 12px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                // margin-left: 12px;
              }
            }
          }

          .saveForm {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;

            button {
              background: #3cdc8c;
              color: var(--white);
              border-radius: 20px;
              padding: 6px 12px;
              border: none;
              margin-right: 6px;
              transition: filter 0.2;
            }

            button:last-child {
              margin: 0;
            }

            button:first-child {
              background: var(--white);
              color: var(--black);
              border: 1px solid var(--lineGray);
            }

            button:hover {
              filter: brightness(0.9);
            }

            button:first-child:hover {
              filter: brightness(0.975);
            }
          }

          .changeTheme {

            // input {
            //     background: var(--white);
            //     color: var(--secondary);
            //     border-radius: 8px;
            //     margin-top: 6px;
            //     // border: 1px solid #;
            //     padding: 4px 6px;
            // }
            .pallete {
              border-radius: 4px;
              margin: 0 10px;
              width: 35px;
              height: 15px;
              border: 2px solid rgb(54, 52, 52);
              cursor: pointer;
            }

            span {
              font-size: 0.95rem;
              display: flex;
              align-items: center;
              padding-right: 12px;
            }

            .iconClose {
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: flex-end;
              padding-right: 12px;
              right: 100px;
            }
          }
        }

        span,
        a {
          font-size: 0.95rem;
          // font-size: 0.95rem;
          cursor: pointer;
          transition: filter 0.2;
        }

        a,
        span:hover {
          filter: brightness(0.4);
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}

.headerNotification {
  padding: 12px;
  background-color: var(--secondary);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  legend {
    color: var(--white);
  }
}

.blockAllOcurrence {
  padding: 0 12px 0 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 20% 15% 15% 50% 5%;
  // justify-content: space-between;
  margin: 8px 0;
  border-bottom: 1px solid var(--secondary);

  .label-active {
    background: var(--primary);
    color: var(--black);
  }

  .changeCard {
    legend:last-child {
      margin-top: 10px;
    }
  }

  textarea {
    padding: 6px 12px;
    width: 100%;
    height: 65px;
    resize: none;
    -webkit-appearance: none;
    outline: none;
    border: 1px solid var(--lineGray);

    font-size: 0.85rem;
    text-align: justify;

    border-radius: 8px;
    box-shadow: 1px 1px #888888;
  }

  textarea::placeholder {
    font-style: italic;
  }

  textarea::-webkit-input-placeholder {
    padding-left: 0;
  }

  label {
    width: 45px;
    height: 15px;
    border-radius: 32px;
    border: 1px solid var(--lineGray);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    background: var(--white);
    color: var(--lineGray);
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    padding: 12px 6px;
    margin: 12px 0;
    position: relative;

    input {
      width: 1.1em;
      height: 1.1em;
      background-color: white;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid var(--lineGray);
      -webkit-appearance: none;
      outline: none;
      cursor: default;
      // margin-right: 12px;
    }

    img {
      position: absolute;
      width: 1.1em;
      height: 1.1em;
      cursor: default;
      // left: 140px;
    }
  }

  legend {
    font-size: 0.85rem;
    font-weight: 500;
  }

  span {
    color: var(--black);
    font-size: 0.75rem;
  }

  .checkbox {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .infosAllOcurrence {
    legend {
      margin: 0;
    }

    label {
      width: 45px;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0;
      width: 250px;
      // height: 55px;
      // overflow-y: scroll;
      outline: none;
      border: none;
      color: var(--black);
      font-size: 0.75rem;
      // text-align: justify;

      border-radius: 8px;
      box-shadow: none;
    }

    label::placeholder {
      font-style: italic;
    }

    label::-webkit-input-placeholder {
      padding-left: 0;
    }
  }

  .blockIconButtons {
    display: flex;

    // flex-direction: column;
    align-items: center;
    // justify-content: center;
    // margin-left: 10px;
  }

  .blockIconButtons:first-child {
    z-index: 999;
    display: flex;
    background-color: #3cdc8c;
    // flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.button-form {
  margin: 20px 0 15px 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    width: 135px;
    height: 35px;
    background: var(--white);
    color: var(--black);
    border-radius: 50px;
    margin-right: 12px;
    padding: 12px;
    -webkit-appearance: none;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  button+button {
    padding-right: 12px;
  }

  button:last-child {
    background: var(--secondary);
    color: var(--white);
    border: 1px solid var(--lineGray);
  }
}