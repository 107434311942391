#teste {
    border-right: 1px solid var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-managerAccounts {
    padding-bottom: 12px;

    .header-form {
        padding: 18px 12px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: var(--secondary);

        legend {
            font-size: 1.05rem;
            font-weight: 600;
            color: var(--white);
        }
    }

    .controller-users {
        form {
            .content-block-selectRole {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;
                max-width: 6rem;
                margin-bottom: 1rem;

                select {
                    padding: 6px 12px;
                    background-color: var(--white);
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }
            }

            .block-form-inputs {
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;
                margin: 0 6px;

                label {
                    margin-bottom: 6px;
                }

                input {
                    padding: 6px 12px;
                    border: 1px solid var(--lineGray);
                    border-radius: 4px;
                    font-size: 0.85rem;
                    -webkit-appearance: none;
                    outline: none;
                    box-shadow: 1px 1px #888888;
                }

                input:focus {
                    background: #ecebeb;
                }

                input::placeholder {
                    font-style: italic;
                }

                input::-webkit-input-placeholder {
                    padding-left: 0;
                }

                button {
                    height: 40px;
                    color: var(--secondary);
                    border-radius: 50px;
                    margin-top: 12px;
                    padding: 12px;
                    // -webkit-appearance: none;
                    // outline: none;
                    transition: filter 0.2s;
                    display: flex;
                    align-items: center;
                    background: var(--white);
                    justify-content: center;
                }

                button:hover {
                    filter: brightness(0.9);
                }

                .warning {
                    border: 1px solid var(--warning);
                }
            }

            .form-button-painel {
                display: flex;
                justify-content: flex-end;
                margin-top: 15px;

                button {
                    width: 135px;
                    height: 40px;
                    color: var(--secondary);
                    border: 1px solid var(--secondary);
                    border-radius: 50px;
                    margin-right: 12px;
                    padding: 12px;
                    // -webkit-appearance: none;
                    // outline: none;
                    transition: filter 0.2s;
                    display: flex;
                    align-items: center;
                    background: var(--white);
                    justify-content: center;
                }

                button:hover {
                    filter: brightness(0.9);
                }

                button+button {
                    padding-right: 12px;
                }

                button:last-child:hover {
                    filter: brightness(0.9);
                }

                button:last-child {
                    color: var(--white);

                    background: var(--secondary);
                    border: 1px solid var(--lineGray);
                }
            }
        }



        .controller-users-titles {
            display: grid;
            grid-template-columns: 20% 12% 20% 10% 10% 20%;
            gap: 12px;
            padding: 14px 0 14px 24px;
            // align-items: flex-start;

            legend {
                // margin-left: -10px;
                font-size: 1.05rem;
                color: var(--secondary);
                font-weight: 600;
            }

            span {
                margin: 0;
                padding: 0;
            }

            textarea {
                padding: 6px 12px;
                width: 100%;
                height: 50px;
                resize: none;
                -webkit-appearance: none;
                outline: none;
                border: 1px solid var(--lineGray);

                font-size: 0.85rem;
                text-align: justify;

                border-radius: 8px;
                box-shadow: 1px 1px #888888;
            }

            .label-active {
                background: var(--primary);
                color: var(--secondary);
                width: 30px;
                height: 30px;
                margin-left: 0 auto;

                border-radius: 32px;
                border: 1px solid var(--lineGray);
                // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

                font-size: 0.85rem;

                display: flex;
                font-weight: 500;
                align-items: center;
                justify-content: center;

                // padding-left: 12px;

                color: var(--secondary);
                cursor: pointer;
                position: relative;
                transition: all 0.4s linear;

                input {
                    width: 0.9em;
                    height: 0.9em;
                    background-color: white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid var(--lineGray);
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    // margin-right: 12px;
                }

                img {
                    position: absolute;
                    width: 1.2em;
                    height: 1.2em;
                    cursor: pointer;
                    // left: 140px;
                }
            }

            .containerCheckbox,
            label {
                width: 30px;
                height: 30px;

                border-radius: 32px;
                border: 2px solid var(--lineGray);
                // box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
                background: var(--white);

                font-size: 0.85rem;

                display: flex;
                font-weight: 500;
                align-items: center;
                justify-content: center;

                // padding-left: 12px;

                color: var(--secondary);
                cursor: pointer;
                position: relative;

                input {
                    width: 0.9em;
                    height: 0.9em;
                    background-color: white;
                    border-radius: 100%;
                    vertical-align: middle;
                    border: 2px solid var(--lineGray);
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                    // margin-right: 12px;
                }

                img {
                    position: absolute;
                    width: 1.2em;
                    height: 1.2em;
                    cursor: pointer;
                    // left: 140px;
                }
            }

            button {
                border-radius: 26px;
                border: none;
                padding: 6px 12px;
                width: 155px;
                background: var(--secondary);
                color: var(--white);
                font-size: 0.85rem;
                transition: filter 0.2s;
            }

            button:hover {
                filter: brightness(0.9);
            }

            label+label {
                margin: 0 12px;
            }
        }
    }
}

.sendButtonAccounts {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    width: 160px;
    background: var(--secondary);
    color: var(--white);
    font-size: 0.85rem;
    transition: background-color 0.2s;
    cursor: pointer;
    width: 70px;
    margin-bottom: 10px;


    &:hover {
        background-color: var(--primary);
        filter: opacity(0.8)
    }
}

.cancelButtonAccounts {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    border: none;
    padding: 6px 12px;
    width: 160px;
    background: var(--white);
    color: var(--blackText);
    border: 2px solid var(--lineGray);
    font-size: 0.85rem;
    transition: filter 0.2s;
    cursor: pointer;
    width: 70px;
    margin: 5px 0 10px 0;


    &:hover {
        filter: brightness(0.9);
    }
}