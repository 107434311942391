.checkbox-switch {
    padding-top: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-left: 100px;

    .title-active {
        font-weight: bold;
        // margin-left: 100px;
    }

    .title-desactive {
        // margin-left: 150px;
    }

    .switch {
        position: absolute;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-left: 80px;
    }

    .switch .active {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .switch .off {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider-active {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #2196f3;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider-active:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .active:checked+.slider-active {
        background-color: #ccc;
    }

    .active:focus+.slider-active {
        box-shadow: 0 0 1px #ccc;
    }

    .active:checked+.slider-active:before {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    .slider-active.round {
        border-radius: 34px;
    }

    .slider-active.round:before {
        border-radius: 50%;
    }

    .slider-off {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider-off:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .off:checked+.slider-off {
        background-color: #2196f3;
    }

    .off:focus+.slider-off {
        box-shadow: 0 0 1px #2196f3;
    }

    .off:checked+.slider-off:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider-off.round {
        border-radius: 34px;
    }

    .slider-off.round:before {
        border-radius: 50%;
    }
}