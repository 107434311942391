@mixin tablet-and-up {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin mobile-and-up {
    @media screen and (min-width: 601px) {
        @content;
    }
}

@mixin tablet-and-down {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-width: 600px) {
        @content;
    }
}

ul,
#liId {
    list-style: none;
    padding: 0;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 1rem;
    // background: linear-gradient(45deg, #209cff, #68e0cf);
    // padding: 3rem 0;
}

.wrapper {
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 50px;
}

h1 {
    font-size: 1.1rem;
    font-family: sans-serif;
}

.sessions {
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
    // overflow: scroll;
    // height: 300px;
}

#liId {
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    &:last-child {
        border: 0px;
        padding-bottom: 0;
    }

    &:before {
        content: "";
        width: 15px;
        height: 15px;
        background: var(--secondary);
        border: 1px solid #4e5ed3;
        box-shadow: 3px 3px 0px #bab5f8;
        box-shadow: 3px 3px 0px #bab5f8;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        top: 0px;
    }
}

#liId:first-child {
    &:before {
        background: var(--primary);
    }
}

.time {
    color: #2a2839;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    @include mobile-and-up {
        font-size: 0.9rem;
    }

    @include mobile-only {
        margin-bottom: 0.3rem;
        font-size: 0.85rem;
    }
}

p {
    color: #4f4f4f;
    font-family: sans-serif;
    line-height: 1.5;
    margin-top: 0.4rem;

    @include mobile-only {
        font-size: 0.9rem;
    }
}