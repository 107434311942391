.container-tracking {
    // display: grid;
    // grid-template-rows: auto auto auto;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: space-between;

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .wrapperTimeline {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 45px;
            width: 100%;

            h1 {
                color: var(--secondary);
            }

            .block {
                margin-top: 10px;
                margin-bottom: 10px;
                width: 65%;
                border: 1px solid var(--primary);
                border-top: 20px solid var(--primary);
                ;

                display: flex;
                justify-content: space-around;

                .info-tracking {
                    padding: 10px 1rem;
                    margin-top: 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .block-info-tracking {
                        display: flex;
                        flex-direction: column;

                        legend {
                            color: var(--secondary);
                            font-weight: bold;
                            margin-bottom: 4px;
                        }

                        label {
                            img {
                                margin-right: 4px;
                                width: 12px;
                                height: 12px;
                            }
                        }

                        span {
                            color: var(--textGray);
                            font-size: 1.04rem;
                        }
                    }
                }

            }


        }
    }
}

// main {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     // height: 400px;
//     .wrapperTimeline {
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         .block {
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             .timeline {
//                 // height: 600px;
//             }
//         }
//     }
// }