// body {
//     overflow: hidden;
// }

.client {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // display: grid;
    // grid-template-rows: 1fr 2fr 1fr ;
    // overflow: hidden; /* Hide scrollbars */
    // height: 400px;

    main {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        h1 {
            font-weight: bold;
            margin-bottom: 30px;
            color: var(--secondary);
        }

        .wrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            input::placeholder {
                padding: 0;
            }

            input {
                border-radius: 4px;
                height: 26px;
                padding: 18px 12px;
                outline: none;
                width: 325px;
                margin-bottom: 6px;
                border: 1px solid var(--lineGray);
            }

            button {
                margin-top: 12px;
                width: 120px;
                border-radius: 20px;
                border: 1px solid var(--lineGray);
                background: var(--secondary);
                color: var(--primary);
                padding: 6px 8px;
                font-weight: 600;
            }
        }
    }

}