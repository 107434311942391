.confirmAlert {
  //Modal confirmation always on top
  z-index: 9999;
}

.bigContent {
  overflow: hidden;
  width: 300px;
  line-break: anywhere;
  text-align: left;
}