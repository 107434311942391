.btnsOnScroll {
  display: none;
  position: fixed;
  z-index: 9999;
  align-items: center;
  // top: 20px;
}


.tableContainer {
  background-color: white;
  height: 100vh;
  width: 100%;
  padding: 1rem 0;
  position: relative;
  margin-bottom: 1rem;

  .downloadContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  p {
    // position: absolute;
    // right: 1rem;
    // top: 1rem;
    color: var(--secondary);
    text-decoration: underline;
    cursor: pointer;
    width: 10rem;
    z-index: 999;
    margin: 0;
  }
}

.relatorioTitle {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  background-color: var(--background);

  .reportButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary);
    color: white;
    width: 15rem;
    padding: 0.5rem;
    border-radius: 99px;
    margin-right: 2rem;
  }

  .inputTime {
    input {
      min-width: 8rem;
      border-radius: 999px;
      border: 1px solid var(--secondary);
      padding: 0.5rem;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
    }

    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
      position: absolute;
      padding-left: 100%;
      right: 0;
    }
  }
}

.ais-RefinementList-checkbox {
  height: 1.2rem;
  width: 1.2rem;
}

.block-checkbox {
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  gap: 1rem;

  .input-checkbox {
    display: flex;
    align-items: flex-start;
    // margin: 0 12px;
    // margin-top: -20px;
    // margin-bottom: 12px;

    input {
      width: 1.3em;
      height: 1.3em;
      background-color: white;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid #ddd;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
      position: relative;
      margin-right: 6px;
    }

    img {
      width: 1.35em;
      height: 1.3em;
      position: absolute;
      cursor: pointer;
    }
  }
}

.noDisplay {
  display: none;
}

.ais-Pagination-list {
  display: flex;
  gap: 0.3rem;
  justify-content: flex-end;

  .ais-Pagination-link {
    padding: 0.2rem;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--textGray);
    background-color: #ddd;
    border: 1px solid #aaaaaa;
  }

  .ais-Pagination-link--selected {
    color: var(--black);
    display: flex;
    justify-content: center;
  }
}

.ais-RefinementList-count {
  color: var(--textGrayCard);
  font-style: normal;
  font-size: 0.7rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lineGray);
  border-radius: 40%;
  min-width: 1.4rem;
  padding: 0.2rem 0.3rem;
}

.ais-RefinementList-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ais-RefinementList-item {
  display: flex;
  gap: 1rem;
}

.ais-SearchBox-form {
  background-color: transparent;
  display: flex;

  .ais-SearchBox-input {
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--textGrayCard);
    padding-bottom: 0.7rem;
    font-size: 0.8rem;

    &::placeholder {
      font-size: 0.7rem;
      font-style: italic;
    }
  }

  .ais-SearchBox-submit {
    background-color: transparent;
    border: none;
    color: var(--textGrayCard);
  }
}

.ais-RefinementList-list {
  display: flex;
  margin-bottom: 0;
}

.ais-Hits-list {
  display: flex;
  flex-flow: wrap;
  gap: 12px;
  padding: 0rem 1rem;
}

.btnsOnScrollVisible {
  height: 80px;
  display: flex;
  position: fixed;
  z-index: 9999;
  align-items: center;
  justify-content: space-around;
  top: 0px;
  background: var(--background);
  border-bottom: 1px solid var(--secondary);
  width: 100%;

  .input-checkbox {
    margin: 0 10px;

    input {
      margin-right: 5px;
    }

    label {
      font-style: italic;
    }
  }

  &:nth-child(even) {
    background: var(--background);
  }

  button {
    background-color: var(--background);
  }

  display: flex;

  .block-checkbox {
    display: flex;
    width: 100%;

    .input-checkbox {
      display: flex;
      align-items: flex-start;

      input {
        width: 1.3em;
        height: 1.3em;
        background-color: white;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ddd;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        position: relative;
      }

      img {
        width: 1.35em;
        height: 1.3em;
        position: absolute;
        cursor: pointer;
      }
    }
  }

  .search {
    padding: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 80px;

    input {
      border: none;
      border-bottom: 1px solid var(--black);
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: start;
      background: var(--background);
      -webkit-appearance: none;
      outline: none;

      font-size: 0.85rem;
      font-style: italic;
    }

    input::placeholder {
      padding: 0;
      font-size: 0.65rem;
    }
  }


  section {
    >.titles {
      display: grid;
      grid-template-columns: 5fr 1fr 1fr 2fr;
      margin: 18px 0;
      padding: 0 12px 0 35px;

      .blockTitles:first-child {
        width: 100%;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
      }

      .blockTitles:last-child {
        width: 100%;
        justify-content: flex-end;
        padding-left: 12px;
      }

      .blockTitles {
        display: flex;
        justify-content: center;
        width: 100%;

        span {
          color: var(--secondary);
          font-weight: 600;
          font-size: 0.95rem;
        }
      }
    }
  }
}

.btnsDocument {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
  background: var(--background);
  z-index: 9999;
  // position: fixed;
  // top: 0;
}

.react-tabs__tab {
  display: flex;
  align-items: center;
  width: 250px;
  height: 50px;
  background: var(--background);
  position: relative;
  border: 1px solid var(--lineGray);
  border-bottom: none;

  legend {
    color: var(--secondary);
    font-size: 1.05rem;
    font-weight: bold;
  }

  .iconClose {
    position: absolute;
    right: 2px;
    top: 4px;
    cursor: pointer;
  }
}

.react-tabs__tab-list {
  margin: 0;
}

.body {
  height: 100vh;
  background: var(--background);

  main {
    background: var(--background);
    padding: 0 12px;

    .manualTabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #d2d2d2;
      padding: 14px 0;
      height: 75px;

      .firstTabs {
        span {
          cursor: pointer;
          padding-left: 32px;
          padding-right: 32px;
          margin-bottom: 2px;
          font-weight: bold;
          color: var(--textGray);
        }

        // Class to change focus in span screen, is used with state isFoccused
        .focusClick {
          color: var(--secondary);
          border-bottom: 3px solid var(--primary);
          padding-bottom: 22px;
          flex: 1;
        }
      }

      .secondTabs {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 20px;
        cursor: pointer;

        img {
          margin-right: 12px;
          width: 15px;
          height: 15px;
        }

        span {
          font-style: italic;
          font-size: 1rem;
        }

        button {
          background: var(--secondary);
          color: var(--white);
          padding: 6px 12px;
          margin-right: 12px;
          border: none;
          border-radius: 26px;
          font-size: 0.95rem;
          transition: 0.2s filter;
        }

        button:hover {
          filter: brightness(0.9);
        }
      }
    }

    .manualTabs:after {
      position: absolute;
      border-bottom: 2px solid var(--primary);
    }

    .buttonFilter {
      display: flex;
      flex-direction: column;
    }

    .buttonContainer {
      display: flex;
    }

    section>.btnsDocument {
      label {
        font-size: 0.95rem;
        margin: 0 8px;
        font-style: italic;
      }

      .createRegister {
        display: flex;
        flex-direction: row;
      }

      .filter {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--secondary);
        font-weight: bold;
        padding: 0 20px;

        // padding-left: 50px;
        // padding-right: 0;
        >span {
          margin-left: 12px;
        }
      }

      .block-checkbox {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;

        .input-checkbox {
          display: flex;
          align-items: flex-start;

          input {
            width: 1.3em;
            height: 1.3em;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          img {
            width: 1.35em;
            height: 1.3em;
            position: absolute;
            cursor: pointer;
          }
        }
      }

      .search {
        margin-left: 100px;
        padding: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        input {
          border: none;
          border-bottom: 1px solid var(--black);
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: start;
          background: var(--background);
          -webkit-appearance: none;
          outline: none;

          font-size: 0.85rem;
          font-style: italic;
        }

        input::placeholder {
          padding: 0;
          font-size: 0.65rem;
        }
      }
    }

    section {
      >.titles {
        display: grid;
        grid-template-columns: 5fr 1fr 1fr 2fr;
        margin: 18px 0;
        padding: 0 10px 0 10px;

        .blockTitles:first-child {
          width: 100%;
          display: grid;
          grid-template-columns: 25% 25% 30% 20%;
        }

        .blockTitles:last-child {
          width: 100%;
          justify-content: flex-end;
          padding-left: 12px;
        }

        .blockTitles {
          display: flex;
          justify-content: center;
          width: 100%;

          span {
            color: var(--secondary);
            font-weight: 600;
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1460px) {
  main {
    section>.btnsDocument {
      .block-checkbox {
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 1271px) {
  .btnDocuments {
    width: 210px;
  }

  .btnsOnScrollVisible {
    width: initial;

    .search {
      justify-content: initial;
      margin: initial;
    }
  }
}

@media (max-width: 560px) {
  .body {
    main {
      .manualTabs {

        // flex-direction: column;
        .firstTabs {
          span {
            padding-left: 22px;
            padding-right: 22px;
          }

          .focusClick {
            color: var(--secondary);
            border-bottom: 3px solid var(--primary);
            padding-bottom: 25px;
            flex: 1;
          }
        }
      }

      section>.btnsDocument {
        .createRegister {
          display: flex;
          flex-direction: column;
        }

        .block-checkbox {
          flex-direction: column;
          padding-left: 22px;

          .input-checkbox {
            margin-bottom: 12px;
          }
        }

        .filter {
          padding: 12px 24px;
        }

        .checkbox {
          max-width: 300px;
        }
      }

      section {
        overflow-x: scroll;

        .titles {
          width: 900px;

          .blockTitles:first-child {
            width: 100%;
            display: grid;
            grid-template-columns: 42% 33% 33%;
          }

          .blockTitles:last-child {
            width: 100%;
            justify-content: flex-end;
            padding-left: 50px;
          }
        }

        .cardsRomaneios {
          width: 900px;
          // overflow-x: scroll;
        }
      }

      // section >
    }
  }
}